import React from "react";
import Carousel from "../../components/editorial/CarouselVideo";
import data from '../../../queries/data/vip';
import Layout from "../../UI/Layout";
import '../../scss/vip.scss';

const index = () => {
  console.log(data.data.wp.vip.dataHeader);

  const carousel = {
    mainCarousel : [
      ...data.data.wp.vip.options
    ]
  }

  const dataHeader = data.data.wp.vip.dataHeader;


  return (
    <Layout className="Editorial white" dataHeader={dataHeader}>
      <Carousel
        noScroll 
        noTitle 
        info={carousel} 
        slideType="mixedSlides" 
        SingleCarouselSlide
        />
    </Layout>
  )
}

export default index;